$(function(){
	forms();
});

function forms(){
	$("#content form").addClass("needs-validation").attr("novalidate","novalidate");
	$("#content form").find("input[required=required]").each(function(){
		$("<div class='invalid-feedback'>"+translation.this_field_is_required+"</div>").insertAfter(this);
	});
	
	// Fetch all the forms we want to apply custom Bootstrap validation styles to
	var forms = document.querySelectorAll('.needs-validation')

	// Loop over them and prevent submission
	Array.prototype.slice.call(forms)
	.forEach(function (form) {
		form.addEventListener('submit', function (event) {
			if (!form.checkValidity()) {
				event.preventDefault()
				event.stopPropagation()
			}

			form.classList.add('was-validated')
		}, false)
	});

	if($("form.ajax").length > 0){
		function addAjax(){
			$("form.ajax:not(.init)").toggleClass("init").each(function(){
				$(this).submit(function(e){
					e.preventDefault();
					var $ajaxForm = $(this);
					$ajaxForm.toggleClass("loading");
					$.ajax({
						url: $(this).attr("action"),
						method: $(this).attr("method"),
						data: $(this).serialize(),
						success: function(data){
							if(data.html){
								$ajaxForm.toggleClass("loading").hide();
								$(data.html).insertAfter($ajaxForm);
								$ajaxForm.prev(".alerts").remove();
								$ajaxForm.remove();
								addAjax();
							}
							if(data.redirect){
								location.href = data.redirect;
							}
						}
					});			
				});	
			});
		}	
		addAjax();
	}
}